@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?3qm5q8");
  src: url("../fonts/icomoon.eot?3qm5q8#iefix") format("embedded-opentype"),
    url("../fonts/icomoon.ttf?3qm5q8") format("truetype"),
    url("../fonts/icomoon.woff?3qm5q8") format("woff"),
    url("../fonts/icomoon.svg?3qm5q8#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^="icon-"],
[class*=" icon-"] {
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-Double-arrow:before {
  content: "\e900";
}
.icon-mail-line-2:before {
  content: "\e901";
}
.icon-Fb:before {
  content: "\e902";
}
.icon-linkedin:before {
  content: "\e903";
}
.icon-Pinterest:before {
  content: "\e904";
}
.icon-Twitter:before {
  content: "\e905";
}
.icon-youtube:before {
  content: "\e906";
}
.icon-award-line:before {
  content: "\e907";
}
.icon-artboard-line:before {
  content: "\e908";
}
.icon-translate:before {
  content: "\e909";
}
.icon-bar-chart-2-line:before {
  content: "\e90a";
}
.icon-Note-text-line:before {
  content: "\e90b";
}
.icon-file-text-line:before {
  content: "\e90c";
}
.icon-user-2:before {
  content: "\e90d";
}
.icon-draft-line:before {
  content: "\e90e";
}
.icon-arrow-up-s-line:before {
  content: "\e90f";
}
.icon-lock-password-line:before {
  content: "\e910";
}
.icon-share-fill:before {
  content: "\e911";
}
.icon-reply-all-fill:before {
  content: "\e912";
}
.icon-calendar-check-line:before {
  content: "\e913";
}
.icon-user-fill-solid:before {
  content: "\e914";
}
.icon-award-fill-solid:before {
  content: "\e915";
}
.icon-discuss-line:before {
  content: "\e916";
}
.icon-user-2-line_tie:before {
  content: "\e917";
}
.icon-pentool:before {
  content: "\e918";
}
.icon-book-mark-fill-solid:before {
  content: "\e919";
}
.icon-arrow-right-s-line-right:before {
  content: "\e91a";
}
.icon-book-line:before {
  content: "\e91b";
}
.icon-shopping-basket-2-line:before {
  content: "\e91c";
}
.icon-trophy-line:before {
  content: "\e91d";
}
.icon-checkbox-circle-fill-solid:before {
  content: "\e91e";
}
.icon-checkbox-circle-fill:before {
  content: "\e91f";
}
.icon-eye-line:before {
  content: "\e920";
}
.icon-call:before {
  content: "\e921";
}
.icon-user-fill:before {
  content: "\e922";
}
.icon-award-fill:before {
  content: "\e923";
}
.icon-flag-fill:before {
  content: "\e924";
}
.icon-video-fill:before {
  content: "\e925";
}
.icon-Solid:before {
  content: "\e926";
}
.icon-book-mark-fill:before {
  content: "\e927";
}
.icon-arrow-up-s-fill:before {
  content: "\e928";
}
.icon-arrow-down-s-fill:before {
  content: "\e929";
}
.icon-close-circle-line:before {
  content: "\e92a";
}
.icon-coupon-3-line:before {
  content: "\e92b";
}
.icon-arrow-right-s-line:before {
  content: "\e92c";
}
.icon-zoom-in-line:before {
  content: "\e92d";
}
.icon-checkbox-circle-line:before {
  content: "\e92e";
}
.icon-menu-2-line:before {
  content: "\e92f";
}
.icon-Settings:before {
  content: "\e930";
}
.icon-Lock:before {
  content: "\e931";
}
.icon-Browser:before {
  content: "\e932";
}
.icon-Destination:before {
  content: "\e933";
}
.icon-subtract-line:before {
  content: "\e934";
}
.icon-add-line:before {
  content: "\e935";
}
.icon-presentation:before {
  content: "\e936";
}
.icon-Fitness:before {
  content: "\e937";
}
.icon-Pencil:before {
  content: "\e938";
}
.icon-Mind:before {
  content: "\e939";
}
.icon-Open-book:before {
  content: "\e93a";
}
.icon-arrow-right-line-right:before {
  content: "\e93b";
}
.icon-arrow-left-line:before {
  content: "\e93c";
}
.icon-Setting:before {
  content: "\e93d";
}
.icon-Microscope:before {
  content: "\e93e";
}
.icon-student-read:before {
  content: "\e93f";
}
.icon-trophy:before {
  content: "\e940";
}
.icon-mail-open-line:before {
  content: "\e941";
}
.icon-account-circle-line:before {
  content: "\e942";
}
.icon-Schoolbag:before {
  content: "\e943";
}
.icon-Class:before {
  content: "\e944";
}
.icon-Headphone:before {
  content: "\e945";
}
.icon-file-list-4-line:before {
  content: "\e946";
}
.icon-phone-line:before {
  content: "\e947";
}
.icon-price-tag-3-line:before {
  content: "\e948";
}
.icon-Laugh:before {
  content: "\e949";
}
.icon-Support:before {
  content: "\e94a";
}
.icon-Play:before {
  content: "\e94b";
}
.icon-map-pin-line:before {
  content: "\e94c";
}
.icon-Bag:before {
  content: "\e94d";
}
.icon-Board:before {
  content: "\e94e";
}
.icon-ribbon:before {
  content: "\e94f";
}
.icon-Smile:before {
  content: "\e950";
}
.icon-scale:before {
  content: "\e951";
}
.icon-book-3-line:before {
  content: "\e952";
}
.icon-science:before {
  content: "\e953";
}
.icon-calculator:before {
  content: "\e954";
}
.icon-compass:before {
  content: "\e955";
}
.icon-pen-tool:before {
  content: "\e956";
}
.icon-lab:before {
  content: "\e957";
}
.icon-microscopes:before {
  content: "\e958";
}
.icon-reading-book:before {
  content: "\e959";
}
.icon-Heart:before {
  content: "\e95a";
}
.icon-send-plane-fill:before {
  content: "\e95b";
}
.icon-calendar-2-line:before {
  content: "\e95c";
}
.icon-Star:before {
  content: "\e95d";
  color: #ffa41b;
}
.off.icon-Star:before {
  content: "\e95d";
  color: #2d284e;
  opacity: 0.15;
}
.icon-phone-fill:before {
  content: "\e95e";
}
.icon-file-list-2-line:before {
  content: "\e95f";
}
.icon-group-line:before {
  content: "\e960";
}
.icon-user-line:before {
  content: "\e961";
}
.icon-live-fill:before {
  content: "\e962";
}
.icon-search-line:before {
  content: "\e963";
}
.icon-mic-off-fill:before {
  content: "\e964";
}
.icon-camera-fill:before {
  content: "\e965";
}
.icon-arrow-right-line:before {
  content: "\e966";
}
.icon-time-line:before {
  content: "\e967";
}
.icon-file-list-3-line:before {
  content: "\e968";
}
.icon-arrow-down-s-line:before {
  content: "\e969";
}
.icon-Badge-icon:before {
  content: "\e96a";
}
.icon-Campus:before {
  content: "\e96b";
}
.icon-Hand---Book:before {
  content: "\e96c";
}
.icon-square:before {
  content: "\e96d";
}
.icon-clock:before {
  content: "\e96e";
}
.icon-student:before {
  content: "\e96f";
}
.icon-research:before {
  content: "\e970";
}
