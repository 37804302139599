:root {
  --color-mgg1: #19bdf0;
  --color-mgg2: #eb65a6;
  --color-mgg3: #ffdc01;
  --color-mgg4: #71bf44;
  --color-white: #fff;
  --color-info-dark: #7d8da1;
  --color-info-light: #dce1eb;
  --color-dark: #363949;
  --color-light: rgba(132, 139, 200, 0.18);
  --color-primary-variant: #111e88;
  --color-dark-variant: #677483;
  --color-background: #f6f6f9;
  --card-border-radius: 2rem;
  --border-radius-1: 0.4rem;
  --border-radius-2: 0.8rem;
  --border-radius-3: 1.2rem;
  --card-padding: 1.8rem;
  --padding-1: 1.2rem;
  --box-shadow: 0 2rem 3rem var(--color-light);
}
.juNRvt {
  display: block !important;
}
.admin {
  margin: 0;
  padding: 0;
  outline: 0;
  appearance: 0;
  border: 0;
  text-decoration: none;
  box-sizing: border-box;
  font-size: 14px;
  width: 100vw;
  height: 100vh;
  font-size: 0.88rem;
  user-select: none;
  overflow-x: hidden;
  color: var(--color-dark);
  background-color: var(--color-background);
  a {
    color: var(--color-dark);
  }
  h1 {
    font-weight: 800;
    font-size: 2.5rem;
  }

  h2 {
    font-weight: 600;
    font-size: 1.4rem;
  }

  h3 {
    font-weight: 500;
    font-size: 1.3rem;
    margin-bottom: 0 !important;
  }
  small {
    font-size: 0.76rem;
  }
  img {
    display: block;
    width: 100%;
    object-fit: cover;
  }
  p {
    color: var(--color-dark-variant);
  }

  b {
    color: var(--color-dark);
  }

  .text-muted {
    color: var(--color-info-dark);
  }
  .container-admin {
    display: grid;
    width: 96%;
    margin: 0 auto;
    gap: 1.8rem;
    grid-template-columns: 1fr 10fr;
  }
  aside {
    height: 100vh;
  }
  aside .toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.4rem;
  }

  aside .toggle .logo {
    display: flex;
    gap: 0.5rem;
  }

  aside .toggle .logo img {
  }
  aside .toggle .close {
    padding-right: 1rem;
    display: none;
  }
  aside .sidebar {
    display: flex;
    flex-direction: column;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
    border-radius: 15px;
    height: 88vh;
    position: relative;
    top: 1.5rem;
    transition: all 0.3s ease;
  }
  aside .sidebar:hover {
    box-shadow: none;
  }
  aside .sidebar a {
    display: flex;
    align-items: center;
    color: var(--color-info-dark);
    height: 6rem;
    gap: 1rem;
    position: relative;
    margin-left: 2rem;
    transition: all 0.3s ease;
  }
  aside .sidebar a span {
    font-size: 1.6rem;
    transition: all 0.3s ease;
  }
  aside .sidebar a:last-child {
    position: absolute;
    bottom: 2rem;
    width: 100%;
  }
  aside .sidebar .home-link {
    position: absolute;
    bottom: 6rem;
  }
  aside .sidebar a.active {
    width: 100%;
    color: var(--color-primary);
    background-color: var(--color-light);
    margin-left: 0;
  }

  aside .sidebar a.active::before {
    content: "";
    width: 6px;
    height: 18px;
    background-color: var(--color-primary);
  }
  aside .sidebar a.active span {
    color: var(--color-primary);
    margin-left: calc(1rem - 3px);
  }

  aside .sidebar a:hover {
    color: var(--color-primary);
  }
  aside .sidebar a:hover span {
    margin-left: 0.6rem;
  }
  // main .analyse {
  //   display: grid;
  //   grid-template-columns: repeat(3, 1fr);
  //   gap: 1.6rem;
  // }

  main .analyse > div {
    background-color: var(--color-white);
    padding: var(--card-padding);
    border-radius: var(--card-border-radius);
    margin-top: 1rem;
    box-shadow: var(--box-shadow);
    cursor: pointer;
    transition: all 0.3s ease;
  }

  main .analyse > div:hover {
    box-shadow: none;
  }
  // main .recent-orders {
  //   margin-top: 1.3rem;
  // }

  // main .recent-orders h2 {
  //   margin-bottom: 0.8rem;
  // }

  // main .recent-orders table {
  //   background-color: var(--color-white);
  //   width: 75%;
  //   padding: var(--card-padding);
  //   text-align: center;
  //   box-shadow: var(--box-shadow);
  //   border-radius: var(--card-border-radius);
  //   transition: all 0.3s ease;
  // }

  main .recent-orders table:hover {
    box-shadow: none;
  }
  main table thead th {
    font-size: 14px;
  }
  main table tbody td {
    // height: 2.8rem;
    // border-bottom: 1px solid var(--color-light);
    color: var(--color-dark-variant);
    font-size: 12px;
  }

  // main table tbody tr:last-child td {
  //   border: none;
  // }

  // main .recent-orders a {
  //   text-align: center;
  //   display: block;
  //   margin: 1rem auto;
  //   color: var(--color-primary);
  // }
  .recent-orders {
    padding: 20px;
  }

  .table-container {
    overflow-x: auto;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  /* Responsive styles */
  @media (max-width: 768px) {
    .recent-orders {
      padding: 10px;
    }

    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      margin: 0 0 1rem 0;
    }

    tr:nth-child(odd) {
      background: #f9f9f9;
    }

    td {
      border: none;
      position: relative;
      padding-left: 50%;
    }

    td:before {
      position: absolute;
      top: 10px;
      left: 10px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      content: attr(data-label);
    }

    .pagination {
      display: flex;
      justify-content: center;
      padding: 10px 0;
    }

    .pagination li {
      margin: 0 5px;
    }

    .pagination button {
      font-size: 14px;
      padding: 5px 10px;
    }
  }

  .right-section {
    margin-top: 1.4rem;
  }

  .right-section .nav {
    display: flex;
    justify-content: end;
    gap: 2rem;
  }

  .right-section .nav button {
    display: none;
  }

  .right-section .dark-mode {
    background-color: var(--color-light);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 1.6rem;
    width: 4.2rem;
    cursor: pointer;
    border-radius: var(--border-radius-1);
  }

  .right-section .dark-mode span {
    font-size: 1.2rem;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .right-section .dark-mode span.active {
    background-color: var(--color-primary);
    color: white;
    border-radius: var(--border-radius-1);
  }

  .right-section .nav .profile {
    display: flex;
    gap: 2rem;
    text-align: right;
  }

  .right-section .nav .profile .profile-photo {
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;
    overflow: hidden;
  }

  .right-section .user-profile {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 1rem;
    background-color: var(--color-white);
    padding: var(--card-padding);
    border-radius: var(--card-border-radius);
    box-shadow: var(--box-shadow);
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .right-section .user-profile:hover {
    box-shadow: none;
  }

  .right-section .user-profile img {
    width: 11rem;
    height: auto;
    margin-bottom: 0.8rem;
    border-radius: 50%;
  }

  .right-section .user-profile h2 {
    margin-bottom: 0.2rem;
  }

  .right-section .reminders {
    margin-top: 2rem;
  }

  .right-section .reminders .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.8rem;
  }

  .right-section .reminders .header span {
    padding: 10px;
    box-shadow: var(--box-shadow);
    background-color: var(--color-white);
    border-radius: 50%;
  }

  .right-section .reminders .notification {
    background-color: var(--color-white);
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0.7rem;
    padding: 1.4rem var(--card-padding);
    border-radius: var(--border-radius-2);
    box-shadow: var(--box-shadow);
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .right-section .reminders .notification:hover {
    box-shadow: none;
  }

  .right-section .reminders .notification .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    width: 100%;
  }

  .right-section .reminders .notification .icon {
    padding: 0.6rem;
    color: var(--color-white);
    background-color: var(--color-success);
    border-radius: 20%;
    display: flex;
  }

  .right-section .reminders .notification.deactive .icon {
    background-color: var(--color-danger);
  }

  .right-section .reminders .add-reminder {
    background-color: var(--color-white);
    border: 2px dashed var(--color-primary);
    color: var(--color-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .right-section .reminders .add-reminder:hover {
    background-color: var(--color-primary);
    color: white;
  }

  .right-section .reminders .add-reminder div {
    display: flex;
    align-items: center;
    gap: 0.6rem;
  }

  // main .analyse > div .status {
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  // }

  // main .analyse h3 {
  //   margin-left: 0.6rem;
  //   font-size: 2rem;
  // }

  // main .analyse .progresss {
  //   position: relative;
  //   width: 92px;
  //   height: 92px;
  //   border-radius: 50%;
  // }

  // main .analyse svg {
  //   width: 7rem;
  //   height: 7rem;
  // }

  // main .analyse svg circle {
  //   fill: none;
  //   stroke-width: 10;
  //   stroke-linecap: round;
  //   transform: translate(5px, 5px);
  // }

  // main .analyse .sales svg circle {
  //   stroke: var(--color-success);
  //   stroke-dashoffset: -30;
  //   stroke-dasharray: 200;
  // }

  // main .analyse .visits svg circle {
  //   stroke: var(--color-danger);
  //   stroke-dashoffset: -30;
  //   stroke-dasharray: 200;
  // }

  // main .analyse .searches svg circle {
  //   stroke: var(--color-primary);
  //   stroke-dashoffset: -30;
  //   stroke-dasharray: 200;
  // }

  // main .analyse .progresss .percentage {
  //   position: absolute;
  //   top: -3px;
  //   left: -1px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   height: 100%;
  //   width: 100%;
  // }

  @media screen and (max-width: 1500px) {
    .container {
      width: 95%;
      grid-template-columns: 7rem auto 23rem;
    }

    aside .logo h2 {
      display: none;
    }

    aside .sidebar h3 {
      display: none;
    }

    aside .sidebar a {
      width: 5.6rem;
    }

    aside .sidebar a:last-child {
      position: absolute;
      margin-top: 5rem;
    }
    aside .sidebar .home-link {
      position: absolute;
      bottom: 6rem;
    }

    main .analyse {
      grid-template-columns: 1fr;
      gap: 0;
    }

    main .new-users .user-list .user {
      flex-basis: 40%;
    }

    main .recent-orders {
      width: 70%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      margin: 2rem 0 0 0.8rem;
    }

    main .recent-orders table {
      width: 83vw;
    }
  }

  @media screen and (max-width: 768px) {
    .container-admin {
      width: 100%;
      grid-template-columns: 1fr;
      padding: 0 var(--padding-1);
    }

    aside {
      position: fixed;
      background-color: var(--color-white);
      width: 50%;
      z-index: 3;
      box-shadow: 1rem 3rem 4rem var(--color-light);
      height: 100vh;
      left: -100%;
      display: none;
      animation: showMenu 0.4s ease forwards;
    }

    @keyframes showMenu {
      to {
        left: 0;
      }
    }

    aside .logo {
      margin-left: 1rem;
    }

    aside .logo h2 {
      display: inline;
    }

    aside .sidebar h3 {
      display: inline;
    }

    aside .sidebar a {
      width: 100%;
      height: 6.4rem;
    }

    aside .sidebar a:last-child {
      position: absolute;
      bottom: 5rem;
    }
    aside .sidebar .home-link {
      position: absolute;
      bottom: 10rem;
    }
    aside .toggle .close {
      display: inline-block;
      cursor: pointer;
    }

    main {
      margin-top: 8rem;
      padding: 0 1rem;
    }

    main .new-users .user-list .user {
      flex-basis: 35%;
    }

    // main .recent-orders {
    //   position: relative;
    //   margin: 3rem 0 0 0;
    //   width: 100%;
    // }

    // main .recent-orders table {
    //   width: 100%;
    //   margin: 0;
    // }

    .right-section {
      width: 94%;
      margin: 0 auto 4rem;
    }

    .right-section .nav {
      position: fixed;
      top: 0;
      left: 0;
      align-items: center;
      background-color: var(--color-white);
      padding: 0 var(--padding-1);
      height: 6rem;
      width: 100%;
      z-index: 2;
      box-shadow: 0 1rem 1rem var(--color-light);
      margin: 0;
    }

    .right-section .nav .dark-mode {
      width: 4.4rem;
      position: absolute;
      left: 66%;
    }

    .right-section .profile .info {
      display: none;
    }

    .right-section .nav button {
      display: inline-block;
      background-color: transparent;
      cursor: pointer;
      color: var(--color-dark);
      position: absolute;
      left: 1rem;
    }

    .right-section .nav button span {
      font-size: 2rem;
    }
    .recent-orders {
      padding: 10px;
    }

    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      margin: 0 0 1rem 0;
    }

    tr:nth-child(odd) {
      background: #f9f9f9;
    }

    td {
      border: none;
      position: relative;
      padding-left: 50%;
    }

    td:before {
      position: absolute;
      top: 10px;
      left: 10px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      content: attr(data-label);
    }

    .pagination {
      display: flex;
      justify-content: center;
      padding: 10px 0;
    }

    .pagination li {
      margin: 0 5px;
    }

    .pagination button {
      font-size: 14px;
      padding: 5px 10px;
    }
  }
}
