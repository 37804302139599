/*-- Variables --*/
:root {
  //Themes colors
  --color-primary: #059dff;
  --color-secondary: #e23e96;
  --color-secondary-alt: #fff6f4;
  --color-tertiary: #ffa41b;
  --color-tertiary-2: #7152e9;
  --color-dark: #231f40;
  --color-body: #6f6b80;
  --color-heading: #231f40;
  --color-white: #ffffff;
  --color-shape: #eeeeee;
  --color-shade: #f5f5f5;
  --color-border: #eeeeee;

  // Typo Color
  --color-black: #000000;
  --color-blackest: #0f0f11;

  // Extra Color
  --color-extra01: #666666;
  --color-extra02: #606770;
  --color-extra03: #fbfbfd;
  --color-extra04: #1a1a1a;
  --color-extra05: #242424;
  --color-extra06: #dbf1c0;
  --color-extra07: #c6ece8;
  --color-extra08: #c3ecce;

  // Notify Colors
  --color-success: #3eb75e;
  --color-danger: #ff0003;
  --color-warning: #ff8f3c;
  --color-info: #1ba2db;

  //Social icon colors
  --color-facebook: #3b5997;
  --color-twitter: #1ba1f2;
  --color-youtube: #ed4141;
  --color-linkedin: #0077b5;
  --color-pinterest: #e60022;
  --color-instagram: #c231a1;
  --color-vimeo: #00adef;
  --color-twitch: #6441a3;
  --color-discord: #7289da;

  //Border-radius
  --border-width: 2px;
  --radius-small: 5px;
  --radius: 10px;
  --radius-big: 16px;

  //Font weight
  --p-light: 300;
  --p-regular: 400;
  --p-medium: 500;
  --p-semi-bold: 600;
  --p-bold: 700;
  --p-extra-bold: 800;
  --p-black: 900;

  //Shadows
  --shadow-primary: 0px 8px 30px rgba(0, 0, 0, 0.04);
  --shadow-light: 1px 1px 6px rgba(0, 0, 0, 0.25);
  --shadow-lighter: 4px 4px 20px 20px rgba(0, 0, 0, 0.01);

  //transition easing
  --transition: 0.3s;
  --transition-transform: transform 0.65s cubic-bezier(0.23, 1, 0.32, 1);

  //Font Family
  --font-primary: "Inter", sans-serif;
  --font-secondary: "Inter", sans-serif;
  --font-awesome: "Font Awesome 5 Pro";

  //Fonts Size
  --font-size-b1: 18px;
  --font-size-b2: 16px;
  --font-size-b3: 14px;
  --font-size-b4: 12px;

  //Line Height
  --line-height-b1: 1.67; //28px
  --line-height-b2: 1.63; //26px
  --line-height-b3: 1.6; //22px
  --line-height-b4: 1.3; //16px

  // Heading Font
  --h1: 72px; //90px 900 1.25
  --h2: 68px; //84px 900 1.24
  --h3: 40px; // 56px 900 1.4
  --h4: 32px; //44px 700  1.38
  --h5: 24px; //36px 1.5
  --h6: 20px; //20px 1.25

  --h1-lineHeight: 1.25;
  --h2-lineHeight: 1.24;
  --h3-lineHeight: 1.4;
  --h4-lineHeight: 1.38;
  --h5-lineHeight: 1.5;
  --h6-lineHeight: 1.6;
}

// Layouts Variation
$custom-lg-device-seven: "only screen and (min-width: 1200px)";
$custom-lg-device-three: "only screen and (min-width: 1750px)";
$custom-lg-device-four: "only screen and (min-width: 1800px)";
$custom-lg-device-five: "only screen and (min-width: 1900px)";
$custom-lg-device-two: "only screen and (min-width: 1401px) and (max-width: 1750px)";
$custom-lg-device-six: "only screen and (min-width: 1401px) and (max-width: 1650px)";
$custom-lg-device-eight: "only screen and (min-width: 1200px) and (max-width: 1450px)";
$extra-device: "only screen and (min-width: 1600px) and (max-width: 1919px)";
$laptop-device: "only screen and (min-width: 1200px) and (max-width: 1599px)";
$custom-laptop-device: "only screen and (min-width: 1200px) and (max-width: 1400px)";
$lg-layout: "only screen and (min-width: 992px) and (max-width: 1199px)";
$md-layout: "only screen and (min-width: 768px) and (max-width: 991px)";
$custom-lg-device: "only screen and (max-width: 1650px)";
$smlg-device: "only screen and (max-width: 1199px)";
$small-tablet: "only screen and (max-width: 991px)";
$sm-layout: "only screen and (max-width: 767px)";
$large-mobile: "only screen and (max-width: 575px)";
$small-mobile: "only screen and (max-width: 479px)";
